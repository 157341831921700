
import * as _ from "lodash";
import Swal from 'sweetalert2'
import draggable from 'vuedraggable'
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import StudyCreationBase from '@/components/StudyCreation/StudyCreationBase.vue'
import AddQuestionModal from '@/components/StudyCreation/AddQuestionModal.vue'
import QuestionSurvey from '~/components/SurveyCreation/QuestionSurvey.vue'
import Collapsable from '~/components/Collapsable/Collapsable.vue'
import Input from '~/components/Input/Input.vue'
import SuiteInput from '~/components/SuiteInput/SuiteInput.vue'
import Button from '@/components/Button.vue'
import ProgressModal from '@/components/StudyCreation/ProgressModal.vue'
import UsersOriginSelector from "@/components/StudyCreation/UsersOriginSelector.vue";
import Criteria from '@/components/StudyCreation/Criteria.vue'
import Viability from '@/components/StudyCreation/Viability.vue'
import WatFocusWelcomePage from '~/components/Execution/WatFocusWelcomePage.vue'
import ModalFullScreen from '~/components/ModalFullScreen/ModalFullScreen.vue';
import TestPreview from '@/components/TestPreview.vue'

import store from '@/store'
import {   
        createQuestion,
        duplicateQuestion,
        addOptionMatrix,
        addValue,
        reorderQuestions,
        canRemoveQuestion,
        removeQuestionValueOption,
      removeQuestionValue} from '~/utils/survey-creation.ts'
import { ProductTypes, userOrigin } from '@/utils/constants.ts'

export default defineComponent({
  name : 'Step3WatFocus',
  components:{
    StudyCreationBase,
    Input,
    SuiteInput,
    AddQuestionModal,
    QuestionSurvey,
    draggable,
    Button,
    ProgressModal,
    Collapsable,
    UsersOriginSelector,
    Criteria,
    Viability,
    WatFocusWelcomePage,
    ModalFullScreen,
    TestPreview
  },
  computed: {
    ...mapGetters([
      'watFocusSteps',
      'clientCountry'
    ]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  props:{
    STEP:[String, Number]
  },
  data(){
    return{
      ranges:[{from:0,to:0}],
      genderValues:[{id:0,value:this.$t('suite_men'), selected: false, count:0 },{id:1, value:this.$t('suite_women'), selected: false, count: 0}],
      geographics:[{name:'', values:[{id:0, selected: false}]}],
      collapseTesters: false,
      collapseCriteria: false,
      collapseWelcome: false,
      showPreview: false,
      questions:new Array(),
      isModalOpen: false,
      errorMessage:this.$t('suite_survey_step3_error'),
      error: false,
      dataIsUpdated: false,
      showTestPreview: false,
      showProgress: false,
      userOrigin:userOrigin,
      executionPreviewTestId:'',
      watFocusConfiguration:{
        usersOrigin:userOrigin.WAT,
        criteria:{},
        welcomePage:{
          text: '',
          title: '',
          legalText:''
        },
        surveyId: null
      },
    }
  },
  methods:{
    openTestPreview(){
      if(this.dataIsUpdated){
        Swal.fire({
          text:this.$t('msg_new_activity_open_preview'),
          icon:"warning",
          customClass:{popup:"swal-customWarning"}
        })
        return
      }
      this.showTestPreview = true
    },
    openPagePreview(){
      this.showPreview = !this.showPreview
    },
    checkViability(event: any){
      this.watFocusConfiguration.criteria = event.criteria
    },
    fetchTest(){
      store.dispatch('getTest',{testId:this.watFocusConfiguration.surveyId}).then((data) => {
        this.executionPreviewTestId = data.test.publicToken
        this.questions = data.testTasks[0].questions != undefined ? data.testTasks[0].questions : []
        if(this.questions.length > 0){
          for(let q of this.questions){
            if(q.type == 'IMAGE_CHOICE' || q.type == 'MULTIPLEIMAGE_CHOICE'){
              q.imageValues = []
              q.imageValues = q.values
              delete q.values
            }
            if (q.placeholders != undefined && q.placeholders != null) {
               q.placeholders = q.placeholders.join('//');
             }
             if(q.textDescriptions != undefined && q.textDescriptions != null) {
               q.textDescriptions = q.textDescriptions.join('//')
             }
          }
        }
      })
    },
    openQuestionModal(){
      this.isModalOpen = !this.isModalOpen
    },
    createQuestion(type: any){
      createQuestion(this.questions, type)
      this.dataIsUpdated = true
    },
    duplicateQuestion(question: any){
      duplicateQuestion(question, this.questions)
      this.dataIsUpdated = true
    },
    canRemoveQuestion(questionIndex: any){
      canRemoveQuestion(questionIndex, this.questions, [], this.$t)
      this.dataIsUpdated = true
    },
    removeQuestionValue(event: any, index: any){
      removeQuestionValue(this.questions, index, event)
      this.dataIsUpdated = true
    },
    removeQuestionValueOption(event: any, index: any){
      removeQuestionValueOption(this.questions, index, event)
      this.dataIsUpdated = true
    },
    reorderQuestions(){
      reorderQuestions(this.questions)
      this.dataIsUpdated = true
    },
    addValue(question: any, addTxt: Boolean){
      addValue(question, addTxt)
      this.dataIsUpdated = true
    },
    addOptionMatrix(question: any){
      addOptionMatrix(question)
      this.dataIsUpdated = true
    },
    beforeSave(onlySave: Boolean){
      this.error = false
      if(this.watFocusConfiguration.usersOrigin == userOrigin.WAT){
        const canSaveSurvey: any = this.canSaveSurvey()
        if(canSaveSurvey.error){
          this.error = true
          this.errorMessage = canSaveSurvey.errorMessage
          return
        }
        let questions = JSON.parse(JSON.stringify(this.questions))
        for(let q of questions){
          if(q.placeholders != undefined && typeof q.placeholders == "string") q.placeholders = q.placeholders.split('//')
          if(q.textDescriptions != undefined && typeof q.textDescriptions == "string") q.textDescriptions = q.textDescriptions.split('//')
        }
        let data = {
          usersOrigin: this.watFocusConfiguration.usersOrigin,
          questions: questions,
          criteria: this.watFocusConfiguration.criteria
        }
        this.saveTest(data, onlySave)
      }
      else {
        const welcomePage = this.watFocusConfiguration.welcomePage
        if(welcomePage.title.length < 3 && welcomePage.text.length < 3 && welcomePage.legalText.length < 3){
          this.error = true
          this.errorMessage = this.$t('suite_watfocus_step3_welcome_error')
          return
        }
        let data = {
          usersOrigin: this.watFocusConfiguration.usersOrigin,
          welcomePage: welcomePage
        }
        this.saveTest(data, onlySave)
      }
    },
    canSaveSurvey(){
      let errors = []
      errors.push(this.questions.length == 0)

      for(let index in this.questions){
        const question: any = this.$refs['Question-' + index]
        errors.push(question.hasErrors())
      }
      const errorMessage = this.$t('suite_survey_step3_error')
      return {
        error: errors.includes(true),
        errorMessage: errorMessage
      }
    },
    saveTest(data: any, onlySave: any){
      this.showProgress = true
      store.dispatch('saveWatFocus', {contractId:this.$route.params.id, data:data})
      .then((data) => {
        this.showProgress = false
        this.dataIsUpdated = false
        if(!onlySave) this.$router.push({name: 'newstudy.step4WatFocus', params:{id: this.$route.params.id}})
        else if(data.testId){
          this.watFocusConfiguration.surveyId = data.testId
          this.fetchTest()
        }
      })
      .catch((response)  => {
        this.error = true
        this.errorMessage = this.$t('suite_study_error_save')
        this.showProgress = false
      })
    },
    fetchWatFocusConfig(){
      store.dispatch('fetchWatFocusConfiguration',{contractId:this.$route.params.id}).then((data) => {
        this.setWatFocusConfiguration(data)
        if(this.watFocusConfiguration.surveyId != null) this.fetchTest()
        if(Object.entries(this.watFocusConfiguration.criteria).length > 1) {
          const viability: any = this.$refs.Viability
          let individualCriteria = []
          let focusCriteria: any = this.watFocusConfiguration.criteria
          if(focusCriteria.ageCriteria) individualCriteria.push({ageCriteria:focusCriteria.ageCriteria})
          if(focusCriteria.genderCriteria) individualCriteria.push({genderCriteria:focusCriteria.genderCriteria})
          if(focusCriteria.provinceCriteria) individualCriteria.push({provinceCriteria:focusCriteria.provinceCriteria})
          if(focusCriteria.regionCriteria) individualCriteria.push({regionCriteria:focusCriteria.regionCriteria})
          const viabilityData = {
            type: ProductTypes.WAT_FOCUS,
            country: this.clientCountry || 'es',
            IR: 100,
            estimatedMinutes: 5,
            criteria:{testerNum:0},
            mobilePercent:0,
            desktopPercent:0,
            individualCriteria: individualCriteria
          }
          viability.updateViability(viabilityData)
        }
      })
    },
    setWatFocusConfiguration(data: any){
      if(!data.usersOrigin) data.usersOrigin = userOrigin.WAT;
      this.watFocusConfiguration.usersOrigin = data.usersOrigin
      this.watFocusConfiguration.criteria = data.criteria != undefined ? data.criteria : {} 
      this.watFocusConfiguration.welcomePage = data.welcomePage == undefined ? this.watFocusConfiguration.welcomePage : data.welcomePage
      this.watFocusConfiguration.surveyId = data.surveyId == undefined ? null : data.surveyId
    }
  },
  mounted(){
    $("html, body").animate({ scrollTop: 0 }, 400);
    this.fetchWatFocusConfig()
  }
})
