
  import { defineComponent } from 'vue';
  import { userOrigin } from '@/utils/constants';
  export default defineComponent({
    name : 'UsersOriginSelector',
    props:{
        origin: {
            type: String
        }
    },
    data(){
        return{
            userOrigin: userOrigin
        }
    }
  })
  