
import { defineComponent } from 'vue';
import Separation from '@/components/StudyCreation/Separation.vue'
import Spinner from '@/components/StudyCreation/Spinner.vue'

export default defineComponent({
  name : 'Cuota',
  components:{
    Separation,
    Spinner
  },
  props: {
    name: String,
    title:{
      type: String,
      default: ''
    },
    quota: Object,
    values:{
      type: Array,
      default: () => []
    },
    quotaError:{
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      isUpdating: false
    }
  },
  methods:{
    updated(){
      this.isUpdating = true
      let vm = this
      setTimeout(function(){
        if(vm.isUpdating){
          vm.isUpdating = false
          vm.$emit('update')
        }
      }, 1000);
    },
    getTestersAvailable(block: any){
      const testersAvailable = block.testersAvailable == undefined || block.testersAvailable == null ? 0 : block.testersAvailable
      return testersAvailable + "/" + block.testerNum
    }
  }
})
