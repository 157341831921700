import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c4bd802"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "CuotaTypeSelector" }
const _hoisted_2 = { class: "CuotaTypeSelector--Tabs" }
const _hoisted_3 = { class: "CuotaTypeSelector--TabsQuestion" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tab = _resolveComponent("Tab")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('suite_study_quota_title')), 1),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (x) => {
        return (_openBlock(), _createBlock(_component_Tab, {
          text: x.text,
          active: _ctx.selectedQuotas.includes(x.id),
          onClick: ($event: any) => (_ctx.$emit('select', x.id))
        }, null, 8, ["text", "active", "onClick"]))
      }), 256))
    ], 512), [
      [_vShow, _ctx.tabs.length > 0]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryTabs, (x) => {
        return (_openBlock(), _createBlock(_component_Tab, {
          text: x.text,
          active: _ctx.selectedCategories.includes(x.id+'-'+x.categoryId),
          onClick: ($event: any) => (_ctx.$emit('select-category', {id:x.id, categoryId: x.categoryId}))
        }, null, 8, ["text", "active", "onClick"]))
      }), 256))
    ], 512), [
      [_vShow, _ctx.categoryTabs.length > 0]
    ])
  ]))
}