
import { defineComponent } from "vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default defineComponent({
  name: "RangeSlider",
  components: {
    VueSlider,
  },
  props:{
    value: Number
  },
  watch:{
    value(newValue){
      this.val = this.value
    }
  },
  data(){
    return{
      val: this.value,
      formatter: v => `${v} | ${100-v}`,
    }
  }
});
