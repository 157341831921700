import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "RangeModeSlider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_slider = _resolveComponent("vue-slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vue_slider, {
      modelValue: _ctx.val,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.val) = $event)),
      height: "8px",
      "tooltip-formatter": _ctx.formatter,
      tooltip: "always",
      onDragEnd: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('drag-end',[_ctx.val, 100 - _ctx.val])))
    }, null, 8, ["modelValue", "tooltip-formatter"])
  ]))
}