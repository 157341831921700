
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import Input from '~/components/Input/Input.vue'
import ValuesModal from '@/components/StudyCreation/ValuesModal.vue'
import ValuesBox from '@/components/StudyCreation/ValuesBox.vue'
import CheckBox from '@/components/StudyCreation/CheckBox.vue'
import Tab from '@/components/StudyCreation/Tab.vue'
export default defineComponent({
  name : 'Criteria',
  components:{
    Input,
    ValuesModal,
    CheckBox,
    ValuesBox,
    Tab
  },
  props:{
    name:String,
    text:{
      type: String,
      default: null
    },
    values:Array,
    tabValues:Array,
    ranges:Array,
    showValues:{
      type: Boolean,
      default: false
    },
    showRanges:{
      type: Boolean,
      default: false
    },
    showTabs:{
      type: Boolean,
      default: false
    },
    showCheckBox:{
      type: Boolean,
      default: false
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },
  computed:{
    rangesText(){
      let text = ""
      let count = 0
      if(this.ranges == undefined) return ""
      for(let range of this.ranges){
        let r: any = range
        text += r.from + " y " + r.to + " " + this.$t('suite_years')
        if (this.ranges.length > 1) {
          count++
          if (this.ranges.length - count >= 1) text += ", "
        }
      }
      return text
    }
  },
  data(){
    return{
      selected:false,
      selectedTab:0,
      showValuesModal:false
    }
  }
})
