import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10f83ebd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ValuesBox" }
const _hoisted_2 = { class: "ValuesBox--BoxValues" }
const _hoisted_3 = { class: "ValuesBox--Value" }
const _hoisted_4 = ["src", "onClick"]
const _hoisted_5 = { class: "ValuesBox--BoxValues" }
const _hoisted_6 = { class: "ValuesBox--Range" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "ValuesBox--Info" }
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_Input = _resolveComponent("Input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (v) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createTextVNode(_toDisplayString(v.value) + " ", 1),
          _withDirectives(_createElementVNode("img", {
            src: require('~/assets/img/studyIcons/remove.svg'),
            onClick: ($event: any) => (_ctx.$emit('remove-value',v.id))
          }, null, 8, _hoisted_4), [
            [_vShow, !_ctx.showCheckBox]
          ]),
          _withDirectives(_createVNode(_component_CheckBox, {
            modelValue: v.selected,
            "onUpdate:modelValue": ($event: any) => ((v.selected) = $event),
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update')))
          }, null, 8, ["modelValue", "onUpdate:modelValue"]), [
            [_vShow, _ctx.showCheckBox]
          ])
        ], 512)), [
          [_vShow, v.selected || _ctx.showCheckBox]
        ])
      }), 256))
    ], 512), [
      [_vShow, _ctx.showValues]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ranges, (range, index) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_Input, {
            type: "number",
            label: _ctx.$t('suite_from'),
            modelValue: range.from,
            "onUpdate:modelValue": ($event: any) => ((range.from) = $event),
            onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update'))),
            disabled: index > 0 || _ctx.disabled
          }, null, 8, ["label", "modelValue", "onUpdate:modelValue", "disabled"]),
          _createVNode(_component_Input, {
            type: "number",
            label: _ctx.$t('suite_to'),
            modelValue: range.to,
            "onUpdate:modelValue": ($event: any) => ((range.to) = $event),
            onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update'))),
            disabled: _ctx.disabled
          }, null, 8, ["label", "modelValue", "onUpdate:modelValue", "disabled"]),
          _withDirectives(_createElementVNode("span", {
            onClick: ($event: any) => (_ctx.$emit('remove-range', index)),
            class: "icon-delete-icon"
          }, null, 8, _hoisted_7), [
            [_vShow, _ctx.ranges.length > 0 && !_ctx.disabled]
          ])
        ], 512)), [
          [_vShow, range.from > 0 || range.to > 0]
        ])
      }), 256)),
      _withDirectives(_createElementVNode("p", _hoisted_8, [
        _createTextVNode(_toDisplayString(_ctx.$t('suite_study_viability_age_valuebox')) + " ", 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.text), 1)
      ], 512), [
        [_vShow, _ctx.ranges && !_ctx.ranges.some(range => range.from === 0 || range.to === 0)]
      ])
    ], 512), [
      [_vShow, _ctx.showRanges]
    ]),
    (_ctx.showAddButton)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "ValuesBox--AddButton",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('add-selected')))
        }, [
          _createElementVNode("p", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('suite_add')) + " " + _toDisplayString(_ctx.name) + " ", 1),
            _createElementVNode("img", {
              src: require('~/assets/img/studyIcons/add.svg'),
              alt: ""
            }, null, 8, _hoisted_9)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}