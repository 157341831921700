
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import CheckBox from '@/components/StudyCreation/CheckBox.vue'
import Input from '~/components/Input/Input.vue'

export default defineComponent({
  name : 'ValuesBox',
  components:{
    CheckBox,
    Input
  },
  props:{
    name:String,
    text:String,
    values:Array,
    ranges:Array,
    showValues:{
      type: Boolean,
      default: false
    },
    showRanges:{
      type: Boolean,
      default: false
    },
    showAddButton:{
      type: Boolean,
      default: false
    },
    showCheckBox:{
      type: Boolean,
      default: false
    },
    disabled:{
      type: Boolean,
      default: true
    }
  }
})
