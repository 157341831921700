
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import StudyIcon from '@/components/StudyIcon.vue'
import Button from '@/components/Button.vue'
import RangeSlider from '~/components/RangeSlider/RangeSlider.vue'
import Criteria from '@/components/StudyCreation/Criteria.vue'
import Category from '@/components/StudyCreation/Category.vue'
import Input from '~/components/Input/Input.vue'
import Separation from '@/components/StudyCreation/Separation.vue'
import Collapsable from '~/components/Collapsable/Collapsable.vue'
import Tab from '@/components/StudyCreation/Tab.vue'
import CuotaTypeSelector from '@/components/StudyCreation/CuotaTypeSelector.vue'
import Cuota from '@/components/StudyCreation/Cuota.vue'
import ValuesModal from '@/components/StudyCreation/ValuesModal.vue'
import InfoModal from '@/components/StudyCreation/InfoModal.vue'
import CountrySelector from '@/components/StudyCreation/CountrySelector.vue'
import DeviceSelector from '@/components/StudyCreation/DeviceSelector.vue'
import store from '@/store'
import * as _ from "lodash";
import { getQuestionCriteria, getQuotaIndex, criteriaHasQuota } from '@/utils/viability.ts'
import { ProductTypes } from '@/utils/constants.ts'
export default defineComponent({
  name : 'Viability',
  components:{
    StudyIcon,
    Button,
    RangeSlider,
    Criteria,
    Category,
    Input,
    Separation,
    Collapsable,
    Tab,
    CuotaTypeSelector,
    Cuota,
    ValuesModal,
    InfoModal,
    CountrySelector,
    DeviceSelector
  },
  data(){
    return{
      collapseGeneral: false,
      collapseCriteria: false,
      collapseCuotas: false,
      tabQuotas:0,
      ranges:[{from:0,to:0}],
      genderValues:[{id:0,value:this.$t('suite_men'), selected: false, count:0 },{id:1, value:this.$t('suite_women'), selected: false, count: 0}],
      geographics:[{name:'', values:[{id:0, selected: false}]}],
      categories:[{identifier:0,selected:false,clientName:'',questions:[{clientText:'',selected:false, id:0, values:[]}]}],
      selectedQuotas:new Array(),
      selectedCategories:new Array(),
      quotas:new Array(),
      selectedQuotaId:-1,
      viability:{
        type: "WAT_SURVEY",
        country: this.clientCountry || 'es',
        IR: 100,
        estimatedMinutes: 5,
        criteria:{},
        mobilePercent:0,
        desktopPercent:0
      },
      testerNum:null,
      showCriteriaModal: false,
      showInfoModal: false,
      showInfoQuotas: false,
      uncrossedQuotas: [{type: '', number: 0}],
      crossedQuotasTotal: 0,
      crossedQuotasValues:new Array(),
      secondUpdate: false,
      noIndependentQuotas: {
        fields: 0,
        blocks: 0
      },
      crossedQuotasCount: 0,
      crossedQuotasSum: 0
    }
  },
  props:{
    quotaIndexWithError:{
      type: Number,
      default: -1
    },
    loading:{
      type: Boolean,
      default: false
    },
    studyType: {
      type: String,
      default: null
    },
    clientCountry:String,
    crossedQuotasError: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters([
      'provinces',
      'regions',
      'nielsenAreas',
      'countriesAvailableGeographic'
    ]),
    quotaTabs(){
      let quotas = []
      if (this.checkGenderValues(this.genderValues)) quotas.push({id:0, text:this.$t('suite_gender')})
      if (this.checkRanges(this.ranges)) quotas.push({id:1, text:this.$t('suite_age')})
      if (this.checkGeographics(this.geographics)) quotas.push({id:2, text:this.$t('suite_geographic')})

      return quotas
    },
    categoryTabs(){
      let questions: any = []
      for(let category of this.categories){
        questions = questions.concat(category.questions.filter(function(question: any){return question.selected}).map(function(question: any){return {id:question.id,text: question.clientText, categoryId: category.identifier}}))
      }
      return questions
    },
    categoriesAsValues(){
      const categories: any = this.categories.map(function(category:any){
        return { id: category.identifier, value: category.categoryName, selected: category.selected}
      })
      return categories
    },
    isAnyCriteriaSelected(){
      return Object.keys(this.viability.criteria).length > 1
    },
    hideIfWatFocus(){
      return this.studyType != ProductTypes.WAT_FOCUS
    }
  },
  methods:{
    checkGenderValues(genderValues: any){
      for (let gender of genderValues) {
        if (gender.selected) return true
      }
      return false
    },
    checkRanges(ranges: any){
      for (let range of ranges) {
        if (range.from > 0 || range.to > 0) return true
      }
      return false
    },
    checkGeographics(geographics: any){
      for (let i = 0; i < 3; i++) {
        for (let geographic of geographics[i].values) {
          if (geographic.selected != null && geographic.selected) return true
        }
      }
      return false
    },
    generateDataForViability(){
      let data:any = this.viability
      this.generateDataForViabilityWatUx()
      data.type = this.studyType
      data.criteria = this.generateCriteria()
      data.quotas = this.quotas
      data.crossedQuotas = this.tabQuotas == 2
      if(this.$route.name == 'newstudy.step2SurveyEdit' || this.$route.name == 'newstudy.step2WatUxEdit' || this.$route.name == 'newstudy.step2ClickTestEdit') data.contractId = this.$route.params.id
      return data
    },
    generateDataForViabilityWatUx(){
      if(this.studyType == ProductTypes.WAT_UX || this.studyType == ProductTypes.WAT_UX_QUANTI || this.studyType == ProductTypes.CLICK_TEST){
        const deviceSelector: any = this.$refs.deviceSelector
        this.viability.mobilePercent = deviceSelector.mobilePercent
        this.viability.desktopPercent = deviceSelector.desktopPercent
      }
    },
    checkViability(){
      this.$emit('check-viability', this.generateDataForViability())
    },
    generateCriteria(){
      let criteria: any = {}

      if (this.checkGenderValues(this.genderValues)) criteria.genderCriteria = this.getMultipleVals(this.genderValues)
      if (this.checkRanges(this.ranges)) criteria.ageCriteria = {from: this.ranges[0].from, to: this.ranges[this.ranges.length - 1].to}
      if (this.checkGeographics(this.geographics)) {
        criteria.provinceCriteria = this.getMultipleVals(this.geographics[0].values)
        criteria.regionCriteria = this.getMultipleVals(this.geographics[1].values)
        criteria.nielsenCriteria = this.getMultipleVals(this.geographics[2].values)
      }

      for(let c of this.categories){
        const category: any = this.$refs['Category-' + c.identifier]
        if(c.selected){
          if(category.questions != undefined && category.questions != null && category.questions.length > 0){
            for(let q of category.questions){
              if(q.selected){
                if(!criteria.categoryCriteria) criteria.categoryCriteria = []
                criteria.categoryCriteria.push(this.getCategoryCriteria(q, c.identifier))
              }
            }
          }
        }
      }
      criteria.testerNum = this.testerNum
      return criteria
    },
    getCategoryCriteria(question: any, categoryId: any){
      let data = {
        categoryId:categoryId,
        questionId:question.id,
        ...this.getMultipleVals(question.values)
      }
      return data
    },
    getMultipleVals(values: any){
      const multipleVals = values.filter(function(v:any){
        return v.selected
      }).map(function(v:any){
        return v.id
      });
      return multipleVals.length > 0 ? {multipleVals} : undefined
    },
    saveGeographicValues(event: any){
      const geographic:any = this.$refs.GeographicCriteria
      const geographicTypeIndex = geographic.selectedTab
      for(let value of this.geographics[geographicTypeIndex].values){
        value.selected = event.values.includes(value.id)
      }
      geographic.selected = true
      this.dataUpdated()
    },
    removeGeographicValue(event: any){
      const geographic:any = this.$refs.GeographicCriteria
      const geographicTypeIndex = geographic.selectedTab
      this.geographics[geographicTypeIndex].values = this.geographics[geographicTypeIndex].values.map(function(v,i){
        if(v.id == event) v.selected = false
        return v
      });
      this.dataUpdated()
    },
    closedQuestionModal(categoryIndex: any){
      let questionsSelected = 0
      for(let q of this.categories[categoryIndex].questions){
        if(q.selected) questionsSelected++
      }
      if(questionsSelected == 0) this.removeCategory(categoryIndex)
    },
    closedValuesModal(questionId: any, categoryIndex: any){
      let valuesSelected = 0
      let questionIndex = 0
      let index = 0
      for(let q of this.categories[categoryIndex].questions){
        if(q.id == questionId) {
          questionIndex = index
          for(let v of q.values){
            const value: any = v
            if(value.selected) valuesSelected ++
          }
        }
        index++
      }
      if(valuesSelected == 0) this.removeCategoryQuestion(categoryIndex, questionIndex)
    },
    removeCategoryQuestion(categoryIndex: any, questionIndex: any){
      this.categories[categoryIndex].questions[questionIndex].selected = false
    },
    saveCategories(event: any){
      for(let category of this.categories){
        category.selected = event.values.includes(Number(category.identifier))
      }
      const category: any = this.$refs['Category-' + event.singleSelected]
      category.openModal()
      this.dataUpdated()
    },
    saveCategoryQuestions(event: any, categoryIndex: any){
      for(let q of this.categories[categoryIndex].questions){
        q.selected = event.includes(Number(q.id))
      }
      this.dataUpdated()
    },
    saveCategoryValues(event: any, categoryIndex: any){
      for(let q of this.categories[categoryIndex].questions){
        if(event.questionId == q.id){
          const values: any = q.values.map(function(value: any){
            value.selected = event.event.includes(Number(value.id))
            return value
          })
          q.values = values
        }
      }
      this.dataUpdated()
    },
    removeCategoryValue(event: any, categoryIndex: any){
      for(let q of this.categories[categoryIndex].questions){
        if(event.questionId == q.id){
          const values: any = q.values.map(function(value: any){
            if(value.id == event.event) value.selected = false
            return value
          })
          q.values = values
        }
      }
      this.dataUpdated()
    },
    removeCategory(categoryIndex: any){
      this.categories[categoryIndex].selected = false
      this.dataUpdated()
    },
    async fetchCriteria(){
      await store.dispatch('fetchCriteria', { country: this.viability.country, lang: this.clientCountry })
      .then((data) => {
        data = data.map(function(category: any){
          category.selected = false
          category.categoryName = category.categoryName.translated
          category.clientName = category.clientName.translated
          category.questions = category.questions.map(function(question: any){
            question.clientText = question.clientText.translated
            if(question.values){
              question.values = question.values.map(function(value: any){
                value.value = value.value.translated
                return value
              })
            }
            return question
          })
          return category
        })
        this.categories = data
      })
    },
    questionsSelectedAsQuota(){
      let categoryQuestions = JSON.parse(JSON.stringify(this.categoryTabs))
      categoryQuestions = categoryQuestions.map(function(q: any){
        q.id = q.id + "-" + q.categoryId
        return q
      })
      const questions = this.quotaTabs.concat(categoryQuestions)
      const vm = this
      const filtered = questions.filter(function(q: any){
        return vm.selectedQuotas.includes(q.id) || vm.selectedCategories.includes(q.id)
      })
      return filtered
    },
    selectQuota(id: any, update: any){
      this.crossedQuotasValues = []
      const indexOf = this.selectedQuotas.lastIndexOf(id)
      if(indexOf == -1) this.selectedQuotas.push(id)
      else this.selectedQuotas.splice(indexOf, 1)
      if(update) this.dataUpdated()
    },
    selectCategoryQuota(event: any, update: any){
      const id = event.id + "-" + event.categoryId
      const indexOf = this.selectedCategories.lastIndexOf(id)
      if(indexOf == -1) this.selectedCategories.push(id)
      else this.selectedCategories.splice(indexOf, 1)
      if(update) this.dataUpdated()
    },
    isCategoryQuestionQuotaSelected(categoryId: any, questionId: any){
      return this.selectedCategories.includes(questionId + "-" + categoryId)
    },
    addRange(){
      if (this.ranges[this.ranges.length - 1].to > 0) {
        const range = {
          from: this.ranges[this.ranges.length - 1].to +1,
          to: this.ranges[this.ranges.length - 1].to + 10
        }
        this.ranges.push(range)
      } else {
        const rangeInit = {
          from: 18,
          to: 50
        }
        this.ranges.shift()
        this.ranges.push(rangeInit)
      }
      this.dataUpdated()
    },
    removeRange(rangeIndex: any){
      this.ranges.splice(rangeIndex, 1)
      this.checkAgeRanges()
      if (this.ranges.length == 0) {
        const defaultRange = { from: 0, to: 0 }
        this.ranges.push(defaultRange)
      }
    },
    checkAgeRanges(){
      if(this.ranges.length == 1){
        if(this.ranges[0].from < 18 || this.ranges[0].from > 100) this.ranges[0].from = 18
        if(this.ranges[0].to > 99 || this.ranges[0].to < 18) this.ranges[0].to = 99
        this.dataUpdated()
        return
      }
      let index = 0
      for(let range of this.ranges){
        if((range.from < 18 || range.from > 99) && index == 0) range.from = 18 // e
        if(range.to > 99 && index + 1 == this.ranges.length) range.to = 99
        if((range.to > 99 || range.to < 18) && index + 1 < this.ranges.length) range.to = this.ranges[index + 1].from - 1
        if(range.to <= range.from) range.to = range.from + 1
        if(this.ranges.length - 1 > index) this.ranges[index + 1].from = range.to + 1
        index++
      }
      this.dataUpdated()
    },
    dataUpdated(){
      const criteria = this.generateCriteria()
      if (this.tabQuotas == 1 && this.questionsSelectedAsQuota().length > 0) {
        this.updateQuotasIndependent(criteria)
      } else if (this.tabQuotas == 2 && this.questionsSelectedAsQuota().length > 0) {
        this.secondUpdate = false
        this.updateQuotasNoIndependent(criteria)
        this.secondUpdate = true
        this.updateQuotasNoIndependent(criteria) // Another execution to update Quota Boxes
      } else {
        this.quotas = []
      }
      this.checkViability()
    },
    updateQuotasIndependent(criteria: any){
      let quotas = []
      if(criteria.genderCriteria && this.selectedQuotas.includes(0)) quotas.push(this.getQuota(this.genderValues.filter(function(v){return v.selected}), "genderCriteria", this.$t('suite_gender'), null))
      if(criteria.provinceCriteria && this.selectedQuotas.includes(2)) quotas.push(this.getQuota(this.geographics[0].values.filter(function(v){return v.selected}), "provinceCriteria", this.$t('suite_form_billing_province'), null))
      if(criteria.regionCriteria && this.selectedQuotas.includes(2)) quotas.push(this.getQuota(this.geographics[1].values.filter(function(v){return v.selected}), "regionCriteria", this.$t('suite_region'), null))
      if(criteria.nielsenCriteria && this.selectedQuotas.includes(2)) quotas.push(this.getQuota(this.geographics[2].values.filter(function(v){return v.selected}), "nielsenCriteria", this.$t('suite_nielsen'), null))
      if(criteria.ageCriteria && this.selectedQuotas.includes(1)) quotas.push(this.getQuotaRanges(this.$t('suite_age')))
      if(criteria.categoryCriteria){
        for(let c of criteria.categoryCriteria){
          const question = getQuestionCriteria(this.categories, c.categoryId, c.questionId)
          if(this.isCategoryQuestionQuotaSelected(c.categoryId, c.questionId)){
            quotas.push(this.getQuota(question.values.filter(function(v: any){return v.selected}),'categoryCriteria',question.clientText, c))
          }
        }
      }
      this.quotas = JSON.parse(JSON.stringify(quotas))
    },
    createQuotaSelectionBlocks(selectionBlocks: any, criteriaType: string, values: any, criteria: any){
      let newSelectionBlocks: any = []
      this.crossedQuotasSum = 0
      this.crossedQuotasCount = 0
      for(let sb of selectionBlocks){
        if(criteriaType == 'ageCriteria'){
          for(let range of this.ranges){
            let sbCopy: any = JSON.parse(JSON.stringify(sb))
            sbCopy.ageCriteria = range
            sbCopy.value += (sbCopy.value.length == 0 ? '' : ',') + range.from + "-" + range.to
            sbCopy.testerNum = this.getSelectionBlockNoIndependentTesterNum(sbCopy.value)
            newSelectionBlocks.push(sbCopy)
          }
        }
        else{
          for(let value of criteria.multipleVals){
            let sbCopy: any = JSON.parse(JSON.stringify(sb))
            if(criteriaType == 'criteria'){
              const question = getQuestionCriteria(this.categories, criteria.categoryId, criteria.questionId)
              sbCopy.categoryCriteria = [{ multipleVals: [value], categoryId:criteria.categoryId, questionId: criteria.questionId }]
              sbCopy.value += (sbCopy.value.length == 0 ? '' : ',') + question.clientText + " - " + this.getQuestionValueById(question.values, value).value
            }
            else{
              sbCopy[criteriaType] = { multipleVals: [value] }
              sbCopy.value += (sbCopy.value.length == 0 ? '' : ',') + this.getQuestionValueById(values, value).value
            }
            sbCopy.testerNum = this.getSelectionBlockNoIndependentTesterNum(sbCopy.value)
            newSelectionBlocks.push(sbCopy)
          }
        }
      }
      return newSelectionBlocks
    },
    getSelectionBlockNoIndependentQuotas(criteria: any, questionForGroups: any, groupQuestionValue: any){
      let selectionBlocks: any = []
      let newSelectionBlocks: any = []

      if(this.selectedQuotaId == -1){
        selectionBlocks.push({value: '', testerNum:0})
      }

      if(criteria.genderCriteria && this.selectedQuotaId == 0){
          let selectionBlock: any = { genderCriteria:{ multipleVals:[groupQuestionValue.id] }, value: groupQuestionValue.value, testerNum: this.getSelectionBlockNoIndependentTesterNum(groupQuestionValue.value) }
          selectionBlocks.push(selectionBlock)
      }

      if(criteria.ageCriteria && this.selectedQuotaId == 1){
          let selectionBlock: any = { ageCriteria:groupQuestionValue, value: groupQuestionValue.from+"-"+groupQuestionValue.to, testerNum:this.getSelectionBlockNoIndependentTesterNum(groupQuestionValue.from+"-"+groupQuestionValue.to) }
          selectionBlocks.push(selectionBlock)
      }

      if(this.selectedQuotaId == 2){
        if(questionForGroups.criteriaType == 'provinceCriteria'){
            let selectionBlock: any = { provinceCriteria:{ multipleVals:[groupQuestionValue.id] }, value: groupQuestionValue.value, testerNum:this.getSelectionBlockNoIndependentTesterNum(groupQuestionValue.value) }
            selectionBlocks.push(selectionBlock)
        }
        if(questionForGroups.criteriaType == 'regionCriteria'){
            let selectionBlock: any = { regionCriteria:{ multipleVals:[groupQuestionValue.id] }, value: groupQuestionValue.value, testerNum:this.getSelectionBlockNoIndependentTesterNum(groupQuestionValue.value) }
            selectionBlocks.push(selectionBlock)
        }
        if(questionForGroups.criteriaType == 'nielsenCriteria'){
            let selectionBlock: any = { nielsenCriteria:{ multipleVals:[groupQuestionValue.id] }, value: groupQuestionValue.value, testerNum:this.getSelectionBlockNoIndependentTesterNum(groupQuestionValue.value) }
            selectionBlocks.push(selectionBlock)
        }
      }

      if(this.selectedQuotaId != -1 && questionForGroups.criteriaType == 'criteria'){
        let selectionBlock: any = { criteria:[{categoryId: questionForGroups.categoryId, questionId: questionForGroups.questionId, multipleVals:[groupQuestionValue.id]}], value: groupQuestionValue.value, testerNum: this.getSelectionBlockNoIndependentTesterNum(groupQuestionValue.value) }
        selectionBlocks.push(selectionBlock)
      }

      if(criteria.genderCriteria && this.selectedQuotaId != 0 && this.selectedQuotas.includes(0)){
        selectionBlocks = this.createQuotaSelectionBlocks(selectionBlocks,'genderCriteria', this.genderValues, criteria.genderCriteria )
      }

      if(criteria.ageCriteria && this.selectedQuotaId != 1 && this.selectedQuotas.includes(1)){
        selectionBlocks = this.createQuotaSelectionBlocks(selectionBlocks, 'ageCriteria', this.ranges, criteria.ageCriteria )
      }

      if(criteria.provinceCriteria && this.selectedQuotas.includes(2) && questionForGroups.criteriaType != 'provinceCriteria'){
        selectionBlocks = this.createQuotaSelectionBlocks(selectionBlocks, 'provinceCriteria', this.geographics[0].values, criteria.provinceCriteria )
      }

      if(criteria.regionCriteria && this.selectedQuotas.includes(2) && questionForGroups.criteriaType != 'regionCriteria'){
        selectionBlocks = this.createQuotaSelectionBlocks(selectionBlocks, 'regionCriteria', this.geographics[1].values, criteria.regionCriteria )
      }

      if(criteria.nielsenCriteria && this.selectedQuotas.includes(2) && questionForGroups.criteriaType != 'nielsenCriteria'){
        selectionBlocks = this.createQuotaSelectionBlocks(selectionBlocks, 'nielsenCriteria', this.geographics[2].values, criteria.nielsenCriteria )
      }

      if(criteria.categoryCriteria){
        for(let c of criteria.categoryCriteria){
          const question = getQuestionCriteria(this.categories, c.categoryId, c.questionId)
          if(this.isCategoryQuestionQuotaSelected(c.categoryId, c.questionId) && this.selectedQuotaId.toString() != c.questionId + "-" + c.categoryId){
            selectionBlocks = this.createQuotaSelectionBlocks(selectionBlocks,'criteria', question.values, c)
          }
        }
      }

      return selectionBlocks
    },
    updateQuotasNoIndependent(criteria: any){
      const questionForGroups: any = this.getQuestionForGroups(criteria)
      let quotas = []

      if(this.selectedQuotaId == -1){
        quotas = [{title:'', name:this.$t('suite_viability_quotas_no_group'), selectionBlocks: this.getSelectionBlockNoIndependentQuotas(criteria,{}, null)}]
      }
      else{
        for(let value of questionForGroups.values){
          const selectionBlocks = this.getSelectionBlockNoIndependentQuotas(criteria, questionForGroups, value)
          quotas.push({title: questionForGroups.title, name: this.selectedQuotaId == 1 ? value.from + "-" + value.to : value.value, selectionBlocks: selectionBlocks})
        }
      }
      this.quotas = quotas
    },
    getQuestionForGroups(criteria: any){
      let group = {}
      if(this.selectedQuotaId == -1) group = {}
      else if(this.selectedQuotaId == 0) group = {values:this.genderValues.filter(function(v){return v.selected}), title:'Género', criteriaType:'genderCriteria'}
      else if(this.selectedQuotaId == 1) group = {values:this.ranges, title:this.$t('suite_age'), genderCriteria:'genderCriteria'}
      else if(this.selectedQuotaId == 2) {
        if(criteria.provinceCriteria) group = {values:this.geographics[0].values.filter(function(v){return v.selected}), title:this.$t('suite_form_billing_province'), criteriaType:'provinceCriteria'}
        if(criteria.regionCriteria) group = {values:this.geographics[1].values.filter(function(v){return v.selected}), title:this.$t('suite_region'), criteriaType:'regionCriteria'}
        if(criteria.nielsenCriteria) group = {values:this.geographics[2].values.filter(function(v){return v.selected}), title:this.$t('suite_nielsen'), criteriaType:'nielsenCriteria'}
      }
      else{
        for(let c of criteria.categoryCriteria){
          if(this.selectedQuotaId.toString() == c.questionId + "-" + c.categoryId){
            const question = getQuestionCriteria(this.categories, c.categoryId, c.questionId)
            group = {values:question.values.filter(function(v: any){return v.selected}), title:question.clientText, criteriaType:'criteria', questionId:c.questionId, categoryId:c.categoryId}
          }
        }
      }
      return group
    },
    getQuota(values: any, criteriaName: any, name: any, criteriaQuestion: any){
      interface SelectionBlock {
        testerNum: Number,
        value?: any | null,
        categoryCriteria?: any | null,
        genderCriteria?: Object | null
      }
      let title = ''
      let selectionBlocks = []
      this.setQuotaBoxes(criteriaName, values)
      let valueIndex = 0
      for(let value of values){
        let selectionBlock: SelectionBlock = { testerNum: this.getSelectionBlockValueTesterNum(criteriaName,value.id, null, null, null, valueIndex) }
        let valuesFormated: any = { multipleVals: [value.id] }
        if(criteriaQuestion){
          selectionBlock.testerNum = this.getSelectionBlockValueTesterNum(criteriaName,value.id, null, criteriaQuestion.categoryId, criteriaQuestion.questionId, valueIndex)
          valuesFormated.categoryId = criteriaQuestion.categoryId
          valuesFormated.questionId = criteriaQuestion.questionId
          selectionBlock[criteriaName as keyof typeof selectionBlock] = [valuesFormated]
          title = this.getCriteriaById(criteriaQuestion.categoryId).clientName
        }
        else selectionBlock[criteriaName as keyof typeof selectionBlock] = valuesFormated
        selectionBlock.value = value.value
        selectionBlocks.push(selectionBlock)
        valueIndex++
      }
      return { name:name, title:title, selectionBlocks: selectionBlocks}
    },
    getQuotaRanges(name: any){
      let selectionBlocks = []
      let index = 0
      this.setQuotaBoxes('ageCriteria', this.ranges)
      for(let range of this.ranges){
        selectionBlocks.push({testerNum: this.getSelectionBlockValueTesterNum('ageCriteria', null, range, null, null, index), ageCriteria: range, value:range.from + "-" + range.to})
        index++
      }
      return { name:name, selectionBlocks: selectionBlocks }
    },
    getSelectionBlockNoIndependentTesterNum(value: any){
      let testerNum = 1
      if (!this.secondUpdate) {
        for(let q of this.quotas){
          for(let block of q.selectionBlocks){
            if(block.value == value) testerNum = block.testerNum
          }
        }
        return testerNum
      } else {
        if (!this.crossedQuotasValues.includes(value)) {
          this.crossedQuotasValues.push(value)
          for (let q of this.quotas) {
            if (this.selectedQuotaId == -1) {
              this.crossedQuotasTotal = q.selectionBlocks.length
            }
          }
          this.crossedQuotasCount++
          if (this.crossedQuotasTotal != null && this.crossedQuotasTotal > 0 && this.testerNum != null) {
            if (this.crossedQuotasCount < this.crossedQuotasTotal) {
              this.crossedQuotasSum = this.crossedQuotasSum + Math.round(this.testerNum / this.crossedQuotasTotal)
              return Math.round(this.testerNum / this.crossedQuotasTotal)
            } else {
              return (this.testerNum - this.crossedQuotasSum)
            }
          }
        } else {
          for(let q of this.quotas){
            for(let block of q.selectionBlocks){
              if(block.value == value) testerNum = block.testerNum
            }
          }
          return testerNum
        }
      }
    },
    getSelectionBlockValueTesterNum(criteriaName: any, valueId: any, range: any, categoryId: any, questionId: any, getQuotaBoxes: any){
      let testerNum = this.getQuotaBoxes(criteriaName)[getQuotaBoxes]
      for(let quota of this.quotas){
        for(let block of quota.selectionBlocks){
          if(criteriaName == 'categoryCriteria' && block.categoryCriteria != undefined){
            if(block.categoryCriteria[0].multipleVals[0] == valueId && block.categoryCriteria[0].questionId == questionId && block.categoryCriteria[0].categoryId == categoryId) testerNum = block.testerNum
          }
          else if(criteriaName != 'categoryCriteria'){
            if(block[criteriaName] != undefined && criteriaName == 'ageCriteria' && range.from == block.ageCriteria.from && range.to == block.ageCriteria.to) testerNum = block.testerNum
            else if(criteriaName != 'ageCriteria' && block[criteriaName] != undefined && block[criteriaName].multipleVals[0] == valueId) testerNum = block.testerNum
          }
        }
      }
      return testerNum
    },
    getCriteriaById(categoryId: any){
      return this.categories.filter(function(c){return c.identifier == categoryId})[0]
    },
    getQuestionValueById(values: any, valueId: any){
      return values.filter(function(v: any){return v.id == valueId})[0]
    },
    setQuotaBoxes(criteriaName: string, values: any){
      const quotaExists = this.uncrossedQuotas.find(block => block.type === criteriaName);
      if (quotaExists) {
        quotaExists.number = values.length;
      } else {
        this.uncrossedQuotas.push({type: criteriaName, number: values.length});
      }
    },
    getQuotaBoxes(criteriaName: string) {
      let selectedQuota = this.uncrossedQuotas.find(block => block.type === criteriaName)
      let totalTesters = this.testerNum
      let firstFields = Math.round(totalTesters! / selectedQuota!.number)
      let sumTesters = 0
      let finalFieldValue = []
      if (selectedQuota != null) {
        for (let i = 0; i < selectedQuota.number; i++) {
          if (selectedQuota.number == i + 1) {
            finalFieldValue.push(totalTesters! - sumTesters)
          } else {
            sumTesters = sumTesters + firstFields
            finalFieldValue.push(firstFields)
          }
        }
      }
      return finalFieldValue
    },
    getCrossedQuotaBoxes() {
      let quotasCount = 0
      for (let q of this.quotas) {
        this.noIndependentQuotas.fields = q.selectionBlocks.length
        quotasCount++
      }
      this.noIndependentQuotas.blocks = quotasCount
      let totalQuotaFields = this.noIndependentQuotas.blocks * this.noIndependentQuotas.fields

      return totalQuotaFields
    },
    updateQuotas(quotas: any){
       _.merge(this.quotas, quotas)
    },
    changetabQuotas(index: any){
      this.tabQuotas = index
      this.dataUpdated()
    },
    goToQuota(event: any){
      $("#Cuota-" + getQuotaIndex(this.quotas,event))[0].scrollIntoView({ behavior: "smooth" });
    },
    updateViability(viability: any){
      this.viability = viability
      this.testerNum = viability.criteria.testerNum
      for(let criteria of viability.individualCriteria){
        if(criteria.categoryCriteria){
          for(let categoryCriteria of criteria.categoryCriteria){
            this.categories = this.categories.map(function(c: any){
              if(c.identifier == categoryCriteria.categoryId){
                c.selected = true
                c.questions = c.questions.map(function(q: any){
                  if(q.id == categoryCriteria.questionId){
                    q.selected = true
                    q.values = q.values.map(function(v: any){
                      v.selected = categoryCriteria.multipleVals.includes(v.id)
                      return v
                    })
                  }
                  return q
                })
              }
              return c
            })
          }
        }
        if(criteriaHasQuota(viability.quotas, criteria) && criteria.genderCriteria) this.selectQuota(0, false)
        if(criteriaHasQuota(viability.quotas, criteria) && criteria.ageCriteria) this.selectQuota(1, false)
        if(criteriaHasQuota(viability.quotas, criteria) && (criteria.provinceCriteria || criteria.regionCriteria || criteria.nielsenCriteria)) this.selectQuota(2, false)
        if(criteriaHasQuota(viability.quotas, criteria) && criteria.categoryCriteria) {
          for(let categoryCriteria of criteria.categoryCriteria){
            this.selectCategoryQuota({id:categoryCriteria.questionId,categoryId: categoryCriteria.categoryId}, false)
          }
        }
        if(criteria.genderCriteria){
          const multipleVals = criteria.genderCriteria.multipleVals
          this.genderValues = this.genderValues.map(function(gv){
            if(multipleVals.includes(gv.id)) gv.selected = true
            return gv
          })
        }
        if(criteria.ageCriteria){
          this.ranges = [criteria.ageCriteria]
        }
        if(criteria.provinceCriteria){
          const multipleVals = criteria.provinceCriteria.multipleVals
          this.geographics[0].values = this.geographics[0].values.map(function(v){
            if(multipleVals.includes(v.id)) v.selected = true
            return v
          })
        }
        if(criteria.regionCriteria){
          const multipleVals = criteria.regionCriteria.multipleVals
          this.geographics[1].values = this.geographics[1].values.map(function(v){
            if(multipleVals.includes(v.id)) v.selected = true
            return v
          })
        }
        if(criteria.nielsenCriteria){
          const multipleVals = criteria.nielsenCriteria.multipleVals
          this.geographics[2].values = this.geographics[2].values.map(function(v){
            if(multipleVals.includes(v.id)) v.selected = true
            return v
          })
        }
      }
      if(viability.quotas != undefined && viability.quotas != null && viability.quotas.length > 0){
        this.tabQuotas = viability.crossedQuotas == true ? 2 : 1
        this.updateQuotas(viability.quotas)
      }
      if(this.studyType == ProductTypes.WAT_UX || this.studyType == ProductTypes.WAT_UX_QUANTI || this.studyType == ProductTypes.CLICK_TEST){
        const deviceSelector: any = this.$refs.deviceSelector
        deviceSelector.desktopPercent = this.viability.desktopPercent
        deviceSelector.mobilePercent = this.viability.mobilePercent
      }
      this.dataUpdated()
    },
    countryUpdated(){
      if(this.countriesAvailableGeographic.includes(this.viability.country)){
        this.updateGeographics()
        this.fetchCriteria()
      }
      this.dataUpdated()
    },
    async updateGeographics(){
      await store.dispatch('getProvinces', { country: this.viability.country })
      await store.dispatch('getRegions', { country: this.viability.country })
      await store.dispatch('getNielsenAreas', { country: this.viability.country })
      this.geographics = [{name:this.$t('suite_form_billing_provinces'), values: this.provinces},{name:this.$t('suite_regions'), values: this.regions},{name:this.$t('suite_nielsen_areas'), values: this.nielsenAreas}]
    }
  },
  async created(){
    await this.updateGeographics()
    await this.checkViability()
  },
  async mounted(){
    await this.fetchCriteria()
  }
})
