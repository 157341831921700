
import { defineComponent } from 'vue';
import Button from '@/components/Button.vue'
import CheckBox from '@/components/StudyCreation/CheckBox.vue'
export default defineComponent({
  name : 'ValuesModal',
  components:{
    Button,
    CheckBox
  },
  props:{
    values:{
      type: Array,
      default: () => []
    },
    title:{
      type: String,
      default:'Selecciona los criterios que necesites'
    },
    singleSelection:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      selectedValues:Array<Boolean>(),
      selectAll: false,
      stopSelectAll: false,
      created: false,
      singleSelected: null
    }
  },
  watch:{
    selectAll(newValue){
      if(this.stopSelectAll){
        this.stopSelectAll = false
        return
      }
      this.selectAllValues(newValue)
    }
  },
  computed:{
    selectedCount(){
      return this.selectedValues.filter(function(value, i){
        return value == true
      }).length
    }
  },
  methods:{
    updatedValue(event: any){
      if(!event.value && this.selectAll){
        this.selectAll = false
        this.stopSelectAll = true
      }
    },
    selectAllValues(addAll: Boolean){
      if(this.values == undefined) return
      for(let v of this.values){
        const value: any = v
        this.selectedValues[value.id] = addAll
      }
    },
    selectSingleValue(value: any){
      if(!this.singleSelection) return
      this.selectedValues[value.id] = true
      this.singleSelected = value.id
      this.saveValues()
    },
    saveValues(){
      let values = this.selectedValues.map(function(selected,i){
        if(selected) return i
      })
      values = values.filter(function(value, i){
        return value != null
      })
      this.$emit('save', {values: values, singleSelected: this.singleSelection ? this.singleSelected : null})
      this.close()
    },
    close(){
      const vm = this
      this.created = false
      setTimeout(function(){
        vm.$emit('close')
      }, 200)
    }
  },
  mounted(){
    for(let v of this.values){
      const value: any = v
      this.selectedValues[value.id] = value.selected
    }
    this.created = true
  }
})
