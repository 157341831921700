
import { defineComponent } from 'vue';
import SelectionBox from '@/components/StudyCreation/SelectionBox.vue'
export default defineComponent({
  name : 'CountrySelector',
  components:{
    SelectionBox
  },
  props: {
    modelValue: {
        type: String,
        default: ''
    }
  },
  watch:{
    modelValue(newValue){
        this.country = newValue
    }
  },
  data(){
    return{
        country: this.modelValue
    }
  },
  methods:{
    change(country: any){
        this.$emit('update:modelValue', country)
    }
  },
  mounted(){
    const selectedElement: any = document.getElementsByClassName('selected')[0]
    selectedElement.style = 'order:1'
  }
})
