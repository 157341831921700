
import { defineComponent } from 'vue';

export default defineComponent({
  name : 'Tab',
  props:{
    text:String,
    active:{
      type: Boolean,
      default: false
    }
  }
})
