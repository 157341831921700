
import { defineComponent, ref } from 'vue';
import { onClickOutside } from '@vueuse/core'
export default defineComponent({
  name : 'InfoModal',
  props:{
    text:{
      type: String,
      default:''
    },
    title:{
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
  const InfoModal = ref(null)

  onClickOutside(InfoModal, (event) => emit('close'))

  return { InfoModal }
}
})
