import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e579d08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Criteria--Selectable" }
const _hoisted_2 = { class: "Criteria--TabBox" }
const _hoisted_3 = {
  key: 0,
  class: "Criteria--Tabs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tab = _resolveComponent("Tab")!
  const _component_ValuesBox = _resolveComponent("ValuesBox")!
  const _component_ValuesModal = _resolveComponent("ValuesModal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["Criteria", {'disabled':_ctx.disabled}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, _toDisplayString(_ctx.name), 1),
      _withDirectives(_createElementVNode("p", null, _toDisplayString(_ctx.text), 513), [
        [_vShow, _ctx.text]
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      (_ctx.showTabs)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabValues, (tab, index) => {
              return (_openBlock(), _createBlock(_component_Tab, {
                text: tab?.name,
                active: _ctx.selectedTab == index,
                onClick: ($event: any) => (_ctx.selectedTab = index)
              }, null, 8, ["text", "active", "onClick"]))
            }), 256))
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_ValuesBox, {
        values: _ctx.showTabs ? _ctx.tabValues[_ctx.selectedTab].values : _ctx.values,
        showValues: "",
        showCheckBox: _ctx.showCheckBox,
        showAddButton: _ctx.showTabs,
        name: _ctx.showTabs ? _ctx.tabValues[_ctx.selectedTab].name : '',
        onAddSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showValuesModal = true)),
        onRemoveValue: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('remove-value',$event))),
        onUpdate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update')))
      }, null, 8, ["values", "showCheckBox", "showAddButton", "name"])
    ], 512), [
      [_vShow, _ctx.showValues]
    ]),
    (_ctx.showRanges)
      ? (_openBlock(), _createBlock(_component_ValuesBox, {
          key: 0,
          disabled: false,
          showRanges: "",
          showAddButton: "",
          name: "rangos",
          ranges: _ctx.ranges,
          text: _ctx.rangesText,
          onAddSelected: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('add-range'))),
          onRemoveRange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('remove-range',$event))),
          onUpdate: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update')))
        }, null, 8, ["ranges", "text"]))
      : _createCommentVNode("", true),
    (_ctx.showValuesModal)
      ? (_openBlock(), _createBlock(_component_ValuesModal, {
          key: 1,
          title: _ctx.$t('suite_study_select_criteria'),
          values: _ctx.showTabs ? this.tabValues[_ctx.selectedTab].values : _ctx.values,
          onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showValuesModal = false)),
          onSave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('save-values',$event)))
        }, null, 8, ["title", "values"]))
      : _createCommentVNode("", true)
  ], 2))
}