
import { defineComponent } from 'vue';
import Tab from '@/components/StudyCreation/Tab.vue'
export default defineComponent({
  name : 'CuotaTypeSelector',
  components:{
    Tab
  },
  emits:['select','select-category'],
  props:{
    tabs:{
      type: Array,
      default: () => []
    },
    selectedQuotas:{
      type: Array,
      default: () => []
    },
    selectedCategories:{
      type: Array,
      default: () => []
    },
    categoryTabs:{
      type: Array,
      default: () => []
    }
  }
})
